import React, { useState } from "react";
import {
  getCurrentUsersPosts,
  createNewPost,
  deletePost,
  getTypes,
  authUserId,
  updatePost,
} from "../lib/pocketbase";
import "../styles/edit.css";
import { useQuery, useMutation } from "@tanstack/react-query";

const Edit = () => {
  const [selectedTypeId, setSelectedTypeId] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [thumbnail, setThumbnail] = useState(null); 
  const [editMode, setEditMode] = useState(false);
  const [postIdToEdit, setPostIdToEdit] = useState(null);

  const userId = authUserId;

  const {
    isLoading,
    isError,
    data: posts,
    error,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["posts", userId],
    queryFn: () => getCurrentUsersPosts(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const { data: types } = useQuery({
    queryKey: ["types"],
    queryFn: () => getTypes(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => {
      return deletePost(id);
    },
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.error("Error deleting post:", error);
    },
  });

  const handleDelete = async (id) => {
    await deleteMutation.mutateAsync(id);
  };

  const createMutation = useMutation({
    mutationFn: (data) => {
      console.log("wtf is this: ", data)
      return createNewPost(
        data.title,
        data.selectedTypeId,
        data.content,
        data.thumbnail
      );
    },
    onSuccess: () => {
      setTitle("");
      setSelectedTypeId("");
      setContent("");
      setThumbnail(null);
      refetch();
    },
    onError: (error) => {
      console.error("Error creating post:", error);
    },
  });

  const updateMutation = useMutation({
    mutationFn: (postId, title, typeId, content, thumbnail) => {
      return updatePost(postId, title, typeId, content, thumbnail);
    },
    onSuccess: () => {
      setTitle("");
      setSelectedTypeId("");
      setContent("");
      setThumbnail(null);
      setEditMode(false);
      setPostIdToEdit(null);
      refetch();
    },
    onError: (error) => {
      console.error("Error updating post:", error);
    },
  });

  const handleCreate = async () => {
    if ( !title || !selectedTypeId || !content || !thumbnail) {
      alert("Please fill out all fields including the thumbnail");
      return;
    }
  
    const data = {

      title: title,
      selectedTypeId: selectedTypeId,
      content: content,
      thumbnail: thumbnail
    };
  
    await createMutation.mutateAsync(data);
  };
  

  const handleEdit = (post) => {
    console.log(post);
    setEditMode(true);
    setPostIdToEdit(post.id);
    setSelectedTypeId(post.typeId);
    setTitle(post.title);
    setContent(post.content);
    setThumbnail(post.thumbnail);
  };
  
  const handleUpdate = async () => {
    if (!title || !selectedTypeId || !content || !thumbnail) {
      alert("Please fill out all fields including the thumbnail");
      return;
    }

    await updateMutation.mutateAsync(
      postIdToEdit,
      title,
      selectedTypeId,
      content,
      thumbnail
    );
  };

  if (isLoading || isFetching) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="wrapper">
      <div className="container center">
        <div className="spacer"></div>
        <div className="edit-wrapper">
          <div>
            <select
              value={selectedTypeId}
              onChange={(e) => setSelectedTypeId(e.target.value)}
              className="input"
            >
              <option value="">Select Type</option>
              {types?.map((typeItem) => (
                <option key={typeItem.id} value={typeItem.id}>
                  {typeItem.type}
                </option>
              ))}
            </select>
          </div>
          <div>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setThumbnail(e.target.files[0])}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Enter a title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              maxLength="50"
              className="input"
            />
          </div>
          <div>
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              maxLength="500"
              placeholder="text"
              className="input"
            />
          </div>
          <button
            className="button"
            onClick={editMode ? handleUpdate : handleCreate}
          >
            {editMode ? "Update Post" : "Create Post"}
          </button>
        </div>
        <div className="spacer"></div>
        <div>
          {posts.items?.map((post) => (
            <div key={post.id}>
              <p>
                {post.title} - {post.type}
              </p>
              <button
                className="button"
                onClick={() => {
                  handleEdit(post);
                }}
              >
                Edit
              </button>
              <button
                className="button"
                onClick={() => {
                  handleDelete(post.id);
                }}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Edit;
