import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { login } from "../lib/pocketbase";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = () => {
    if (!username || !password) {
      window.alert("Invalid login creds");
      return;
    }
    login(username, password);
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  return (
    <div className="wrapper">
      <div className="container center">
        <h2 className="heading-2">Login</h2>

        <div className="mb-4">
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700"
          >
            Username:
          </label>
          <input
            type="text"
            id="username"
            className="cta"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password:
          </label>
          <input
            type="password"
            id="password"
            className="cta"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="cta"
          onClick={() => handleLogin()}
        >
          Login
        </button>
        <div className="spacer"></div>
        <h2 className="heading-2">Dont have an account?</h2>
        <Link to="/signup" className="cta">
          Signup
        </Link>
      </div>
    </div>
  );
}

export default Login;
