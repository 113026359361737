import { Outlet, Navigate } from "react-router-dom";
import React from 'react';
import  {isUserValid}  from "../lib/pocketbase";

const LoggedIn = () => {
    return (
        isUserValid ?  <Navigate to="/"/> : <Outlet/>
    );
};

export default LoggedIn;
