import React, { useState } from "react";
import {
  getUserByName,
  updateAvatar,
  authUserId,
  signOut,
  getPostByUser,
  getUserReactions,
  getUser,
  updateBio,
  getType,
  getImage,
  changePassword,
  changeUsername,
  deleteAccount,
} from "../lib/pocketbase";
import { useParams, Link, useNavigate } from "react-router-dom";
import "../styles/profile.css";
import { useQuery, useQueries, useMutation } from "@tanstack/react-query";

function ProfilePage() {
  const { username } = useParams();
  const userId = authUserId;
  const [showBioPopup, setShowBioPopup] = useState(false);
  const [showUsernamePopup, setShowUsernamePopup] = useState(false);
  const [bio, setBio] = useState("");
  const [newPassword2, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword2, setOldPassword] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    data: user,
    error,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserByName(username),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const { data: posts } = useQuery({
    queryKey: ["post"],
    queryFn: () => getPostByUser(user.id),
    enabled: !!user,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const { data: reactions } = useQuery({
    queryKey: ["reactions"],
    queryFn: () => getUserReactions(userId),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const reactionsMap = {};
  reactions?.items.forEach((reaction) => {
    reactionsMap[reaction.post] = reaction;
  });

  const authorAndTypeQueries = useQueries({
    queries: (posts || []).flatMap((post) => [
      {
        queryKey: ["author", post.user],
        queryFn: () => getUser(post.user),
        refetchOnWindowFocus: false,
        enabled: !!post.user,
      },
      {
        queryKey: ["type", post.type],
        queryFn: () => getType(post.type),
        refetchOnWindowFocus: false,
        enabled: !!post.type,
      },
      {
        queryKey: ["image", post.id],
        queryFn: () => getImage(post, post.thumbnail), // Assuming 'thumbnail' is the property where the image URL is stored
        refetchOnWindowFocus: false,
        enabled: !!post.thumbnail, // Assuming 'thumbnail' is the property where the image URL is stored
        refetchOnMount: false,
      },
    ]),
  });

  const avatarMutation = useMutation({
    mutationFn: ({ userId, imageFile }) => {
      return updateAvatar(userId, imageFile); // Destructuring the object
    },
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      // Handle error, e.g., show an error message
      console.error("Error updating bio:", error);
    },
  });

  const handleAvatarChange = async (event) => {
    const file = event.target.files[0];

    const data = {
      userId: user.id,
      imageFile: file,
    };
    console.log(data);
    if (file) {
      try {
        await avatarMutation.mutateAsync(data);
      } catch (error) {
        console.error("Error uploading new avatar:", error);
      }
    }
  };

  const bioMutation = useMutation({
    mutationFn: (bioData) => updateBio(bioData.userId, bioData.bio),
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      // Handle error, e.g., show an error message
      console.error("Error updating bio:", error);
    },
  });

  const handleBioUpdate = async () => {
    setShowBioPopup(false);

    const bioData = {
      userId: user.id,
      bio: bio,
    };

    try {
      await bioMutation.mutateAsync(bioData);
    } catch (error) {
      console.error("Error updating bio:", error);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword2 !== confirmPassword) {
      console.error("Passwords do not match");
      return;
    }
    try {
      await changePassword(user.id, oldPassword2, newPassword2, confirmPassword);
      // Optionally, you can reset form fields or show a success message
      setNewPassword("");
      setConfirmPassword("");
      setOldPassword("");
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };

  const handleUsernameChange = async (e) => {
    e.preventDefault();

    try {
      await changeUsername(user.id, newUsername);
      // Optionally, you can reset form fields or show a success message
      setNewUsername("");
    } catch (error) {
      console.error("Error changing username:", error);
    }
  };

  const accountDeletionMutation = useMutation({
    mutationFn: () => deleteAccount(user.id), // Call deleteAccount function with user ID
    onSuccess: () => {
      signOut(); // Sign out the user after successful deletion
      navigate("/"); // Redirect to home page or any other page
    },
    onError: (error) => {
      console.error("Error deleting account:", error);
      // Handle error, e.g., show an error message to the user
    },
  });

  const handleDeleteAccount = async () => {
    try {
      await accountDeletionMutation.mutateAsync();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const handleCancel = () => {
    setBio("");
    setShowBioPopup(false);
    setShowUsernamePopup(false);
  };

  if (isLoading || isFetching) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="wrapper">
      {showBioPopup && (
        <div className="bio-popup" onClick={handleCancel}>
          <div className="bio-popup-inner" onClick={(e) => e.stopPropagation()}>
            <textarea
              className="bio-textarea"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              placeholder="Update your bio..."
            />
            <div className="popup-buttons">
              <button className="cta" onClick={handleCancel}>
                Cancel
              </button>
              <button className="cta-danger" onClick={handleBioUpdate}>
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="container">
        <div className="spacer"></div>

        <div className="ppp">
          <img
            src={user.avatarUrl}
            alt={`${user.username}'s avatar`}
            className="user-avatar-profile"
          />
          {authUserId === user?.id ? (
            <div className="ml">
              <label htmlFor="file-upload" className="cta">
                Change profile pic
              </label>
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
                style={{ display: "none" }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <h1 className="username">{user.username}</h1>
        <div className="bio">
          <p> {user.bio ? user.bio : "No bio available."}</p>
        </div>
        <div className="sm-spacer"></div>
        {authUserId === user?.id ? (
          <button className="cta" onClick={() => setShowBioPopup(true)}>
            Update Bio
          </button>
        ) : (
          <></>
        )}
        <div className="spacer"></div>
        <h2 className="heading-2">Posts</h2>
        <div className="sm-spacer"></div>
        {posts?.map((post, index) => {
          const authorQuery = authorAndTypeQueries[index * 3]; // Author data for current post
          const typeQuery = authorAndTypeQueries[index * 3 + 1]; // Type data for current post
          const imageQuery = authorAndTypeQueries[index * 3 + 2];
          const postReaction = reactionsMap[post.id];

          return (
            <Link
              to={`/blogpost/${post.id}`}
              className="categorie-wrapper"
              key={post.id}
            >
              <div>
                {typeQuery.data ? (
                  <p>{typeQuery.data.type}</p>
                ) : (
                  <div>Type loading...</div>
                )}
                <p className="post-title">{post.title}</p>
                <img className="thumbnail" src={imageQuery.data} />
                <p className="post-description">
                  {post.content.length > 30
                    ? `${post.content.substring(0, 30)}...`
                    : post.content}
                </p>
              </div>
              <div className="right-wrapper">
                <div className="reaction-wrapper">
                  <div
                    className="reaction-button-cat"
                    //onClick={() => handleReactionClick(post.id, "good")}
                    style={{
                      backgroundColor:
                        postReaction?.reaction === "good"
                          ? "lightgreen"
                          : "initial",
                    }}
                  >
                    ❤️ {post.reactionCounts.good}
                  </div>
                  <div
                    className="reaction-button-cat"
                    //onClick={() => handleReactionClick(post.id, "meh")}
                    style={{
                      backgroundColor:
                        postReaction?.reaction === "meh" ? "orange" : "initial",
                    }}
                  >
                    🫤 {post.reactionCounts.meh}
                  </div>
                  <div
                    className="reaction-button-cat"
                    //onClick={() => handleReactionClick(post.id, "bad")}
                    style={{
                      backgroundColor:
                        postReaction?.reaction === "bad" ? "salmon" : "initial",
                    }}
                  >
                    👎 {post.reactionCounts.bad}
                  </div>
                </div>
                <div className="right-wrapper">
                  {/* ... reaction buttons */}
                  {authorQuery.isLoading ? (
                    <div>Username loading...</div>
                  ) : (
                    authorQuery.data && (
                      <div className="user-wrapper">
                        <p>Written by {authorQuery.data.username}</p>
                        <img
                          src={authorQuery.data.avatarUrl}
                          className="user-avatar"
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </Link>
          );
        })}

        {authUserId === user?.id ? (
          <div className="profile-page">
            <Link to={`/edit`} className="cta">
              Create a new post
            </Link>
            <div className="spacer"></div>

            <div className="spacer"></div>
            <h2 className="heading-2">Settings</h2>
            {/* <button className="cta" onClick={() => setShowUsernamePopup(true)}>
            Change username
          </button> */}
            <div className="sm-spacer"></div>
           
            <form onSubmit={handlePasswordChange}>
              <input
                type="password"
                placeholder="Old Password"
                value={oldPassword2}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="New Password"
                value={newPassword2}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <button className="cta" type="submit">
                Change Password
              </button>
            </form>
            <div className="sm-spacer"></div>
            {/* <button className="cta">Change Password</button> */}
            <form onSubmit={handleUsernameChange}>
              <input
                type="text"
                placeholder="New Username"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                required
              />
              <button className="cta" type="submit">
                Change Username
              </button>
            </form>
            <div className="sm-spacer"></div>
            <button className="cta-danger" onClick={signOut}>
              Signout
            </button>
            <div className="sm-spacer"></div>
            <button className="cta-danger" onClick={handleDeleteAccount}>Delete account</button>
            <div className="sm-spacer"></div>
            <p>This will delete your acount but not any of your posts or reactions</p>
            <div className="spacer"></div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default ProfilePage;
