import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Navbar2.css'; 
import { isUserValid, authUserId, getProfileByUserId } from '../../lib/pocketbase';

function Navbar2() {
  const [user, setUser] = useState(null);
  const userId = authUserId; 
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await getProfileByUserId(userId);
        setUser(userProfile);
        // If you have separate state for avatar URL, you can set it here
        // setAvatarUrl(userProfile.avatarUrl);
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
        // Handle errors as needed
      }
    };

    fetchUserProfile();
  }, [userId]);

  return (
    <div className="wrapper-2">
      <div className="container-2">
        <nav className="navbar2">
          <ul className="nav-list2">
            <div className="thing">
              <li className="nav-item2">
                <Link to="/" className="nav-link2">
                  Skin Care Hacks
                </Link>
              </li>

              </div>
                
                      <Link to="/edit" className="cta">
                        Share Hack
                      </Link>
               
                   
              {isUserValid && user ? (
                <>

                  <Link to={`/profile/${user.username}`} className="nav-link2 userlink">
                    <h1>{user.username}</h1>
                    <img
                      src={user.avatarUrl} // Use the correct property for the user's avatar URL
                      className="user-avatar"
                    />
                    </Link>

                </>
              ) : (
                <div className="thing">
                  <li className="nav-item2">
                    <Link to="/login" className="nav-link2">
                      Login
                    </Link>
                  </li>
                  
                </div>
              )}
            
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Navbar2;
