import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Edit from "./pages/Edit";
import BlogPost from "./pages/Blogpost";
import ProfilePage from "./pages/Profile";
import NotFound from "./pages/NotFound";
import SignUp from "./pages/Signup";
import "./styles/App.css";
import Navbar2 from "./components/Navbar/Navbar2";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./utils/queryClient";
import ProtectedRoute from "./middleware/protectedRoutes";
import LoggedIn from "./middleware/loggedIn";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Navbar2 />
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/edit" element={<Edit />} />
            <Route path="/profile/:username" element={<ProfilePage />} />
          </Route>
          <Route index element={<Home />} />
          <Route element={<LoggedIn />}>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
          </Route>
          <Route path="/blogpost/:id" element={<BlogPost />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </Router>
    </QueryClientProvider>
  );
}

export default App;
