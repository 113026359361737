import PocketBase from "pocketbase";
export const pb = new PocketBase(
  "https://pocketbase-production-c4f3.up.railway.app"
);
pb.autoCancellation(false);

export const isUserValid = pb.authStore.isValid;
export const authUserId = pb.authStore.model?.id;

/*-----------------------------------------------------------//
User
//-----------------------------------------------------------*/

export async function getUserByName(username) {
  console.log(username);
  const userProfile = await pb
    .collection("users")
    .getFirstListItem(`username = '${username}'`);

  const avatarUrl = pb.files.getUrl(userProfile, userProfile.avatar);
  return { ...userProfile, avatarUrl };
}

export async function getCurrentUsersPosts() {
  return await pb.collection("posts").getList(1, 100, {
    filter: `user.id = "${authUserId}"`,
  });
}

export async function getType(typeId) {
  return await pb.collection("types").getOne(typeId);
}

export async function getUser(userId) {
  const user = await pb.collection("users").getOne(userId);
  const avatarUrl = pb.files.getUrl(user, user.avatar);
  user.avatarUrl = avatarUrl;

  return user;
}

export async function getImage(post, url) {
  const image = pb.files.getUrl(post, url);
  console.log(image)
  return image
}

export async function getProfileByUserId(userID) {
  try {
    const userProfile = await pb.collection("users").getOne(userID);
    const avatarUrl = pb.files.getUrl(userProfile, userProfile.avatar);
    return { ...userProfile, avatarUrl };
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
}

export async function getUserReactions(userID) {
  const reactions = await pb.collection("reactions").getList(1, 200, {
    filter: `user.id = "${userID}"`,
  });
  console.log("reactions: ", reactions)
  return reactions
}

/*-----------------------------------------------------------//
Posts
//-----------------------------------------------------------*/

export async function getPostByUser(userId) {
  const postsResponse = await pb.collection("posts").getList(1, 50, {
    filter: `user.id = "${userId}"`,
  });
  const posts = await processPosts(postsResponse.items);
  return posts;
}

export async function getPostReactions(postId, userId) {
  try {
    const reactionsResponse = await pb.collection("reactions").getList(1, 200, {
      filter: `post = "${postId}"`,
    });
    const reactionCounts = { good: 0, meh: 0, bad: 0 };
    let userReaction = null;

    reactionsResponse.items.forEach((reaction) => {
      reactionCounts[reaction.reaction] =
        (reactionCounts[reaction.reaction] || 0) + 1;
      if (reaction.user === userId) {
        userReaction = reaction.reaction;
      }
    });

    reactionsResponse.reactionCounts = reactionCounts;
    reactionsResponse.userReaction = userReaction;
    return reactionsResponse;
  } catch (error) {
    console.error("Error fetching post with reactions:", error);
    throw error;
  }
}

export async function getPostById(postId) {
  try {
    const post = await pb.collection("posts").getOne(postId);
    return post;
  } catch (error) {
    console.error("Error fetching post with reactions:", error);
    throw error;
  }
}

export async function getTypePosts(type, searchTitle) {
  console.log("type: ", type);
  try {
    if (type == "" && searchTitle == "") {
      console.log(true);
      const postsResponse = await pb.collection("posts").getFullList();
      console.log("posts: ", postsResponse);
      const posts = await processPosts(postsResponse);
      return posts;
    } else if (type == "" && searchTitle != "") {
      const postsResponse = await pb.collection("posts").getList(1, 50, {
        filter: `title ~ "${searchTitle}"`,
      });
      console.log("posts-by-type: ", postsResponse);
      const posts = await processPosts(postsResponse.items);
      return posts;
    } else {
      const filter = searchTitle
        ? `title ~ "${searchTitle}" && type.id = "${type}"`
        : `type.id = "${type}"`;

      const postsResponse = await pb.collection("posts").getList(1, 50, {
        filter,
      });
      console.log("posts-by-type: ", postsResponse);
      const posts = await processPosts(postsResponse.items);
      return posts;
    }
  } catch (error) {
    console.error("Failed to fetch posts:", error);
    throw error;
  }
}

async function processPosts(posts) {
  const processedPosts = [];
  for (const post of posts) {
    const reactionsResponse = await pb.collection("reactions").getList(1, 200, {
      filter: `post = "${post.id}"`,
    });

    const reactionCounts = reactionsResponse.items.reduce(
      (acc, reaction) => {
        acc[reaction.reaction] = (acc[reaction.reaction] || 0) + 1;
        return acc;
      },
      { good: 0, meh: 0, bad: 0 }
    );

    processedPosts.push({ ...post, reactionCounts });
  }
  return processedPosts;
}
export async function createNewPost(title, typeId , content, thumbnail) {
  try {
    const data = {
      user: authUserId,
      title: title,
      type: typeId,
      content: content,
      thumbnail: thumbnail,
    };

    console.log("Data to be sent to createNewPost:", data);

    const newPost = await pb.collection("posts").create(data);
    return newPost;
  } catch (error) {
    console.error("Error creating post:", error);
    throw error;
  }
}




export async function deletePost(id) {
  let confirm = window.confirm("are you sure you want to delete this");
  if (!confirm) {
    return;
  }
  await pb.collection("posts").delete(id);
}



export async function addOrUpdateReaction({ postId, userId, reaction_type }) {
  // Check if the user already reacted to the post
  console.log( postId, userId, reaction_type );
  const existingReactions = await pb.collection("reactions").getList(1, 200, {
    filter: `post.id = "${postId}" && user.id = "${userId}"`,
  });
  //console.log(existingReactions);
  const hasReacted = existingReactions.items.length > 0;
  const reaction = hasReacted ? existingReactions.items[0] : null;

  if (hasReacted) {
    // If the reaction is the same, you might want to remove it or leave as is
    if (reaction.field === reaction_type) {
      // Optionally remove the reaction or return to indicate no change needed
    } else {
      // Update the existing reaction if it's different
      await pb
        .collection("reactions")
        .update(reaction.id, { reaction: reaction_type });
      // Here you would also update the counts on the post
      // Decrement the count for the old reaction and increment the count for the new one
    }
  } else {
    // Create a new reaction
    await pb.collection("reactions").create({
      post: postId,
      user: userId,
      reaction: reaction_type,
    });
  }
  return true;
}

export async function getTypes() {
  try {
    const response = await pb.collection("types").getFullList(); // Adjust if necessary to match your API
    console.log("types: ", response)
    return response; // This should return an array of types
  } catch (error) {
    console.error("Error fetching types:", error);
    throw error;
  }
}

/*-----------------------------------------------------------//
Auth
//-----------------------------------------------------------*/

export async function login(username, password) {
  await pb.collection("users").authWithPassword(username, password);
  window.location.reload();
}

export async function signOut() {
  pb.authStore.clear();
  window.location.reload();
}

export async function changePassword(userID, oldPassword2, newPassword2, passwordConfirm) {
  const data = {
    password: newPassword2,
    passwordConfirm: passwordConfirm,
    oldPassword: oldPassword2,
  };
  const record = await pb.collection("users").update(userID, data);
  return record
}

export async function changeUsername(userID, newUsername) {

  const data = {
    username: `${newUsername}`,
  };
  const record = await pb.collection("users").update(userID, data);
  return record
}

export async function updateBio(userID, newBio) {
  const data = {
    bio: `${newBio}`,
  };
  const record = await pb.collection("users").update(userID, data);
  return record
}

export async function signUp(username, password) {
  const data = {
    username: username,
    password: password,
    passwordConfirm: password,
  };
  await pb.collection("users").create(data);
  await pb.collection("users").authWithPassword(username, password);
  window.location.reload();
}

export async function updateAvatar(userId, imageFile) {
  console.log("i got it: ", userId, imageFile)
  const formData = new FormData();
  formData.append("avatar", imageFile);

  try {
    const updatedUser = await pb.collection("users").update(userId, formData);
    return updatedUser;
  } catch (error) {
    console.error("Error updating avatar:", error);
    throw error;
  }
}


export async function deleteAccount(userID) {
  const record =  await pb.collection('users').delete(userID);
  return record
}



export async function updatePost(postID, title, typeId, content, thumbnail) {
  try {
  const data = {
    title: title,
    content: content,
    user: pb.authStore.model.id,
    type: typeId,
    thumbnail: thumbnail,
  };
  const record = await pb.collection('posts').update(postID, data);
  return record
} catch (error) {
  console.error("Error updating post:", error);
  throw error;
}
}
