import React from 'react';
import Header from '../components/Home/Header';
import '../styles/home.css';
import Categorie from './Categorie';

const Home = () => {
  return (
    <div>
      <Header />
      <Categorie/>
    </div>
  );
};

export default Home;
