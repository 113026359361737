import "../../styles/Header.css";
import React from 'react';
import '../../styles/Navbar2.css'; 


const Header = () => {


  return (
    <div className="wrapper-2">
      <div className="container-2">
        <div className="home-logo-wrapper heroimage">
        <div>
        <text className="home-name">Skin Care Hacks</text>
        <h1 className="home-title">Beacause filters can only do so much</h1>
        </div>
        </div>
      <div className="spacer"></div>
      </div>
    </div>
  );
};
export default Header;
