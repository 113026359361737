import React from "react";
import { useParams, Link } from "react-router-dom";
import {
  getUser,
  getPostById,
  addOrUpdateReaction,
  authUserId,
  getType,
  getPostReactions,
  getImage
} from "../lib/pocketbase";
import "../styles/blogpost.css";
import { useQuery, useMutation } from "@tanstack/react-query";

function BlogPost() {
  const { id } = useParams();
  const userId = authUserId;

  const {
    isLoading,
    isError,
    data: post,
    error,
    isFetching,
  } = useQuery({
    queryKey: ["post", id],
    queryFn: () => getPostById(id, userId),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const postAuthor = post?.user;
  const postTypeId = post?.type;

  const { data: author } = useQuery({
    queryKey: ["author", postAuthor],
    queryFn: () => getUser(postAuthor),
    refetchOnWindowFocus: false,
    enabled: !!postAuthor,
    staleTime: Infinity,
  });

  const { data: image } = useQuery({
    queryKey: ["image", id],
    queryFn: () => getImage(post, post.thumbnail), // Assuming 'thumbnail' is the property where the image URL is stored
    refetchOnWindowFocus: false,
    enabled: !!post, // Assuming 'thumbnail' is the property where the image URL is stored
    staleTime: Infinity,
    refetchOnMount: false,
  })

  const { data: type } = useQuery({
    queryKey: ["type", postTypeId],
    queryFn: () => getType(postTypeId),
    refetchOnWindowFocus: false,
    enabled: !!postTypeId,
    staleTime: Infinity,
  });

  const { data: reaction, refetch: refetchReactions } = useQuery({
    queryKey: ["reactions", id, userId],
    queryFn: () => getPostReactions(id, userId),
    enabled: !!post,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const addReactionMutation = useMutation({
    mutationFn: addOrUpdateReaction,
    refetchOnWindowFocus: false,
  });

  const handleReactionClick = async (reaction) => {
    const data = {
      postId: post.id,
      userId: userId,
      reaction_type: reaction,
    };
    console.log(data);
    try {
      await addReactionMutation.mutateAsync(data);
      refetchReactions();
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading || isFetching) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const isReactionDataAvailable = reaction && !reaction.isLoading && !reaction.isError;

  return (
    <div className="wrapper">
      <div className="container">
        <div className="spacer"></div>
        {post ? (
          <div>
            {isReactionDataAvailable && (
              <>
              <button
                className="reaction-button"
                onClick={() => handleReactionClick("good")}
                style={{
                  backgroundColor:
                    reaction.userReaction === "good" ? "lightgreen" : "initial",
                }}
              >
                ❤️ {reaction.reactionCounts.good}
              </button>
              <button
                className="reaction-button"
                onClick={() => handleReactionClick("meh")}
                style={{
                  backgroundColor:
                    reaction.userReaction === "meh" ? "orange" : "initial",
                }}
              >
                🫤 {reaction.reactionCounts.meh}
              </button>
              <button
                className="reaction-button"
                onClick={() => handleReactionClick("bad")}
                style={{
                  backgroundColor:
                    reaction.userReaction === "bad" ? "salmon" : "initial",
                }}
              >
                👎 {reaction.reactionCounts.bad}
              </button>
              </>
            )}
            <h1 className="title">{post.title}</h1>
            {author ? (
              <Link to={`/profile/${author.username}`} className="user-wrapper">
                <p>Written by {author.username}</p>
                <img src={author.avatarUrl} className="user-avatar" />
              </Link>
            ) : (
              <div>username not found</div>
            )}
            <div className="spacer"></div>
            <img className="thumbnail" src={image}/>
            {type ? <p>{type.type}</p> : <div>Type loading...</div>}
            <div className="spacer"></div>
            <p>{post.content}</p>
          </div>
        ) : (
          <div>Post not found.</div>
        )}
      </div>
    </div>
  );
}

export default BlogPost;
