import { Outlet, Navigate } from "react-router-dom";
import React from 'react';
import  {isUserValid}  from "../lib/pocketbase";

const ProtectedRoute = () => {
    return (
        isUserValid ? <Outlet/> : <Navigate to="/login"/>
    );
};

export default ProtectedRoute;
