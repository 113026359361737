import React, { useState } from "react";
import {
  getUser,
  getTypePosts,
  getUserReactions,
  authUserId,
  getTypes,
  getType,
  getImage,
} from "../lib/pocketbase";
import { Link } from "react-router-dom";
import "../styles/categorie.css";
import { useQuery, useQueries, useQueryClient } from "@tanstack/react-query";

function Categorie() {
  const [searchTitle, setSearchTitle] = useState("");
  const [selectedTypeId, setSelectedTypeId] = useState("");
  const userId = authUserId;
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: posts,
    error,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["posts", selectedTypeId, searchTitle],
    queryFn: () => getTypePosts(selectedTypeId, searchTitle),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnMount: false,
  });

  const { data: reactions } = useQuery({
    queryKey: ["reactions"],
    queryFn: () => getUserReactions(userId),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnMount: false,
  });

  const reactionsMap = {};
  reactions?.items.forEach((reaction) => {
    reactionsMap[reaction.post] = reaction;
  });

  const { data: types } = useQuery({
    queryKey: ["types"],
    queryFn: () => getTypes(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnMount: false,
  });

  const authorAndTypeQueries = useQueries({
    queries: (posts || []).flatMap((post) => [
      {
        queryKey: ["author", post.user],
        queryFn: () => getUser(post.user),
        refetchOnWindowFocus: false,
        enabled: !!post.user, 
        refetchOnMount: false,
      },
      {
        queryKey: ["type", post.type],
        queryFn: () => getType(post.type),
        refetchOnWindowFocus: false,
        enabled: !!post.type,
        refetchOnMount: false,
      },
      {
        queryKey: ["image", post.id],
        queryFn: () => getImage(post, post.thumbnail), // Assuming 'thumbnail' is the property where the image URL is stored
        refetchOnWindowFocus: false,
        enabled: !!post.thumbnail, // Assuming 'thumbnail' is the property where the image URL is stored
        refetchOnMount: false,
      },
    ]),
  });

  const handleSearchTitleChange = (e) => {
    const newTitle = e.target.value;
    setSearchTitle(newTitle);
    queryClient.invalidateQueries(["posts", selectedTypeId, newTitle]);
  }; 

  const handleSelectedTypeChange = (e) => {
    const newTypeId = e.target.value;
    setSelectedTypeId(newTypeId);
    //queryClient.invalidateQueries(["posts", newTypeId, searchTitle]);
  };

  if (isLoading || isFetching) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="wrapper">
      <div className="container">
        <div>
        <p className="pp">Browse skincare hacks</p>
        </div>
        <div className="wrapper">
        <div className="spacer"></div>
          <h1 className="title">Hacks</h1>

        </div>
        <form onSubmit={(e) => e.preventDefault()}>
        <input
          type="text"
          placeholder="Search by Title"
          className="input"
          value={searchTitle}
          onChange={handleSearchTitleChange}
        />
        <select
          value={selectedTypeId}
          onChange={handleSelectedTypeChange}
          className="input"
        >
          <option value="">All</option>
          {types?.map((type) => (
            <option key={type.id} value={type.id}>
              {type.type}
            </option>
          ))}
        </select>
        </form>
        <div className="spacer"></div>
        <div className="container-list">
        {posts?.map((post, index) => {
          const authorQuery = authorAndTypeQueries[index * 3]; // Author data for current post
          const typeQuery = authorAndTypeQueries[index * 3 + 1]; // Type data for current post
          const imageQuery = authorAndTypeQueries[index * 3 + 2];
          const postReaction = reactionsMap[post.id];

          return (
            <Link
              to={`/blogpost/${post.id}`}
              className="categorie-wrapper"
              key={post.id}
            >
                {typeQuery.data ? (
                  <p>{typeQuery.data.type}</p>
                ) : (
                  <div>Type loading...</div>
                )}
                <img className="thumbnail" src={imageQuery.data}/>
              <div>
                <p className="post-title">{post.title}</p>
                <p className="post-description">
                  {post.content.length > 30
                    ? `${post.content.substring(0, 30)}...`
                    : post.content}
                </p>
              </div>
              <div className="right-wrapper">
                <div className="reaction-wrapper">
                  <div
                    className="reaction-button-cat"
                    //onClick={() => handleReactionClick(post.id, "good")}
                    style={{
                      backgroundColor:
                      postReaction?.reaction === "good"
                          ? "lightgreen"
                          : "initial",
                    }}
                  >
                    ❤️ {post.reactionCounts.good}
                  </div>
                  <div
                    className="reaction-button-cat"
                    //onClick={() => handleReactionClick(post.id, "meh")}
                    style={{
                      backgroundColor:
                      postReaction?.reaction === "meh" ? "orange" : "initial",
                    }}
                  >
                    🫤 {post.reactionCounts.meh}
                  </div>
                  <div
                    className="reaction-button-cat"
                    //onClick={() => handleReactionClick(post.id, "bad")}
                    style={{
                      backgroundColor:
                      postReaction?.reaction === "bad" ? "salmon" : "initial",
                    }}
                  >
                    👎 {post.reactionCounts.bad}
                  </div>
                </div>
                <div className="right-wrapper">
                  {/* ... reaction buttons */}
                  {authorQuery.isLoading ? (
                    <div>Username loading...</div>
                  ) : (
                    authorQuery.data && (
                      <div className="user-wrapper">
                        <p>Written by {authorQuery.data.username}</p>
                        <img
                          src={authorQuery.data.avatarUrl}
                          className="user-avatar"
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      </div>
    </div>
  );
}
export default Categorie;
