import React, { useState } from "react";
import { signUp } from "../lib/pocketbase";
import { Link } from "react-router-dom";

function SignUp() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSignUp = () => {
    if (!username || !password) {
      window.alert("invalid login creds");
      return;
    }
    signUp(username, password);
  };

  return (
    <div className="wrapper">
      <div className="container center">
        <h2 className="heading-2">SignUp</h2>

        <div className="mb-4">
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700"
          >
            Username:
          </label>
          <input
            type="text"
            id="username"
            className="cta"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password:
          </label>
          <input
            type="password"
            id="password"
            className="cta"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="cta" onClick={() => handleSignUp()}>
          SignUp
        </button>
        <div className="spacer"></div>
        <h2 className="heading-2">Already have an account?</h2>
        <Link to="/login" className="cta">
          Login
        </Link>
      </div>
    </div>
  );
}

export default SignUp;
